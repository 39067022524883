import React from 'react';
import { Layout, Menu, Avatar, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;

const CenteredLayout = (props: { children: React.ReactNode }) => {
  return (
    <Layout>
      <div className="h-screen flex flex-col items-center justify-center container mx-auto">
        {props.children}
      </div>
    </Layout>
  );
};

const WithHeaderLayout = (props: { children: React.ReactNode }) => {
  return (
    <Layout className="h-screen">
      <Header className="fixed z-10 w-full">
        <div className="container mx-auto">
          <div className="flex items-center">
            <img
              className="w-24 mr-8"
              src={process.env.PUBLIC_URL + '/assets/qore-logo-white.svg'}
              alt=""
            />
            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['projects']}>
              <Menu.Item key="projects">Projects</Menu.Item>
              <Menu.Item key="templates">Templates</Menu.Item>
            </Menu>
            <Dropdown
              trigger={['click']}
              placement="bottomRight"
              overlay={
                <Menu>
                  <Menu.Item>
                    <Link to="/logout" className="text-red-600">
                      <LogoutOutlined />
                      Logout
                    </Link>
                  </Menu.Item>
                </Menu>
              }
            >
              <div title="User menu" className="ml-auto cursor-pointer">
                <Avatar src="https://i.pravatar.cc/300" />
              </div>
            </Dropdown>
          </div>
        </div>
      </Header>
      <Content className="pt-16 overflow-auto">
        <div className="py-12 container mx-auto">{props.children}</div>
      </Content>
    </Layout>
  );
};

export { CenteredLayout, WithHeaderLayout };
