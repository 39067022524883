import React from 'react';
import { CenteredLayout } from './Layout';

const Loading: React.FC = () => {
  return (
    <CenteredLayout>
      <img
        className="w-42 animate-pulse"
        src={process.env.PUBLIC_URL + '/assets/qore-logo.svg'}
        alt="Qore"
      />
    </CenteredLayout>
  );
};

export default Loading;
