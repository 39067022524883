import { UrlUserPath } from './url';
import { callApi } from '../common';

export type APITeam = {
  id: string;
  name: string;
  description: string;
  logo: string;
  createdAt?: string;
  updatedAt?: string;
  owner?: string;
  isDefault: boolean;
  type?: 'personalWorkspace' | 'enterprise' | 'sharedProject';
};

export type Team = APITeam & {
  get(
    limit?: number,
    offset?: number,
    sort?: { [key: string]: any }
  ): Promise<{ teams: Team[]; totalCount: number }>;
  update(team: Partial<APITeam>): Promise<void>;
  delete(): Promise<void>;
};

export class TeamImpl implements Team {
  id: string;
  name: string;
  description: string;
  logo: string;
  createdAt?: string;
  updatedAt?: string;
  owner?: string;
  isDefault: boolean;
  type?: 'personalWorkspace' | 'enterprise' | 'sharedProject' | undefined;
  _url: UrlUserPath;
  _token: string;
  _orgId: string;
  constructor(params: APITeam & { url: UrlUserPath; userToken: string; orgId: string }) {
    this.id = params.id;
    this.name = params.name;
    this.description = params.description;
    this.logo = params.logo;
    this.createdAt = params.createdAt;
    this.updatedAt = params.updatedAt;
    this.owner = params.owner;
    this.isDefault = params.isDefault;
    this.type = params.type;
    this._url = params.url;
    this._token = params.userToken;
    this._orgId = params.orgId;
  }

  async get(limit = 10, offset = 0, sort = {}): Promise<{ teams: Team[]; totalCount: number }> {
    const { nodes, totalCount } = await callApi(
      {
        method: 'get',
        url: this._url.teams(this.id),
        params: { limit, offset, ...sort },
      },
      this._token
    );
    return {
      teams: nodes.map(
        (row: APITeam) =>
          new TeamImpl({
            ...row,
            userToken: this._token,
            url: this._url,
            orgId: this.id,
          })
      ),
      totalCount,
    };
  }

  async update(team: Partial<APITeam>) {
    await callApi(
      {
        method: 'patch',
        url: this._url.teams(this._orgId, this.id),
        data: team,
      },
      this._token
    );
  }

  async delete() {
    await callApi(
      {
        method: 'delete',
        url: this._url.teams(this._orgId, this.id),
      },
      this._token
    );
  }
}
