import axios from 'axios';

type Flag = { name: string; active: boolean };

export default async function getFlags() {
  try {
    const res = await axios.get<{ nodes: Flag[] }>(process.env.REACT_APP_FLAGS_API!);

    return res.data.nodes;
  } catch (error) {
    return Promise.reject(error.response);
  }
}
