import './index.css';
import 'boxicons/css/boxicons.min.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import smoothscroll from 'smoothscroll-polyfill';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';

Sentry.init({
  enabled: process.env.REACT_APP_SENTRY_ENABLED === 'true',
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_DEPLOY_ENV,
  normalizeDepth: 11,
  tracesSampleRate: 1.0,
  integrations: [
    new Integrations.BrowserTracing(),
    new ExtraErrorDataIntegration({
      depth: 10,
    }),
  ],
});

Sentry.setTag('infra', process.env.REACT_APP_INFRA);
Sentry.setTag('appName', process.env.REACT_APP_APP_NAME);
Sentry.setTag('clientName', process.env.REACT_APP_CLIENT_NAME);

smoothscroll.polyfill();

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
