import CurrentUserContext from 'app/contexts/CurrentUserContext';
import { OrganizationProvider } from 'app/contexts/OrganizationContext';
import { TeamProvider } from 'app/contexts/TeamContext';
import { FlagsProvider } from 'app/modules/common/flags/FlagsContext';
import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from 'react-router-dom';
import { SWRConfig } from 'swr';
import Loading from './app/common/Loading';
import { AuthContext, AuthProvider } from './app/modules/auth/AuthContext';
import LoginPage from './app/modules/auth/LoginPageV2';

const ForgotPasswordPage = React.lazy(() => import('app/modules/auth/ForgotPasswordPage'));
const ForgotSuccessPage = React.lazy(() => import('app/modules/auth/ForgotSuccessPage'));
const ForgotPasswordExpiredPage = React.lazy(
  () => import('app/modules/auth/ForgotPasswordExpiredPage')
);
const ResetPasswordPage = React.lazy(() => import('app/modules/auth/ResetPasswordPage'));
const ResetSuccessPage = React.lazy(() => import('app/modules/auth/ResetSuccessPage'));
const RegisterFormPage = React.lazy(() => import('./app/modules/register/RegisterFormPage'));
const RegisterThankyouPage = React.lazy(
  () => import('./app/modules/register/RegisterThankyouPage')
);
const RegisterCompanyPage = React.lazy(() => import('./app/modules/register/RegisterCompanyPage'));
const ProjectsPage = React.lazy(() => import('./app/modules/projects/ProjectsPage'));
const ProjectPage = React.lazy(
  () => import(/* webpackPrefetch: true */ './app/modules/project/ProjectPage')
);
const DashboardPage = React.lazy(
  () => import(/* webpackPrefetch: true */ './app/modules/dashboard/DashboardPage')
);

const AccountSetting = React.lazy(() => import('app/modules/AccountSetting/AccountSetting'));
const Organization = React.lazy(
  () => import(/* webpackPrefetch: true */ 'app/modules/organization/Organization')
);

const InvitationPage = React.lazy(() => import('app/modules/common/InvitationPage'));

const withAuth = ({ redirectPath = '/login' } = {}) => (
  Component: React.FC<RouteComponentProps>
) => (props: RouteComponentProps) => {
  const { localToken } = React.useContext(AuthContext);

  return !!localToken ? (
    <Component {...props} />
  ) : (
    <Redirect
      to={{
        pathname: redirectPath,
        state: { from: props.location },
      }}
    />
  );
};

const ProtectedRoutes = {
  ProjectsPage: withAuth()(ProjectsPage),
  ProjectPage: withAuth()(ProjectPage),
  DashboardPage: withAuth()(DashboardPage),
};

const LogoutPage = () => {
  const { logout } = React.useContext(AuthContext);

  React.useEffect(() => {
    logout();

    window.location.replace('/login');
  });

  return <Loading />;
};

const App: React.FC = () => (
  <Router>
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        errorRetryCount: 1,
      }}
    >
      <FlagsProvider>
        <CurrentUserContext.Provider>
          <AuthProvider>
            <OrganizationProvider>
              <TeamProvider>
                <React.Suspense fallback={<Loading />}>
                  <Switch>
                    <Route exact path="/login" component={LoginPage} />
                    <Route exact path="/logout" component={LogoutPage} />
                    <Route exact path="/register/form" component={RegisterFormPage} />
                    <Route exact path="/register/finish" component={RegisterThankyouPage} />
                    <Route exact path="/register/company" component={RegisterCompanyPage} />
                    <Route exact path="/auth/forgot-password" component={ForgotPasswordPage} />
                    <Route
                      exact
                      path="/auth/forgot-password/success"
                      component={ForgotSuccessPage}
                    />
                    <Route
                      exact
                      path="/auth/forgot-password/expired"
                      component={ForgotPasswordExpiredPage}
                    />
                    <Route exact path="/auth/invitations/join" component={InvitationPage} />
                    <Route exact path="/auth/reset-password" component={ResetPasswordPage} />
                    <Route exact path="/auth/reset-password/success" component={ResetSuccessPage} />
                    <Route path="/account-setting" component={AccountSetting} />
                    <Route path="/projects" component={ProtectedRoutes.ProjectsPage} />
                    <Route
                      exact
                      path={[
                        '/',
                        '/personal-workspace',
                        '/shared-projects',
                        '/templates',
                        '/help',
                        '/orgs/:orgsId/teams/:teamId/:menu',
                      ]}
                      component={ProtectedRoutes.DashboardPage}
                    />
                    <Route
                      path="/orgs/:orgId/teams/:teamId/projects/:projectId"
                      component={ProtectedRoutes.ProjectPage}
                    />
                    <Route path="/orgs/:orgId" component={Organization} />
                  </Switch>
                </React.Suspense>
              </TeamProvider>
            </OrganizationProvider>
          </AuthProvider>
        </CurrentUserContext.Provider>
      </FlagsProvider>
    </SWRConfig>
  </Router>
);

export default App;
