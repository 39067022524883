import { CurrentUser } from 'app/modules/sdk/project';
import { createContext } from 'react-immersive';

const CurrentUserContext = createContext({} as { user?: CurrentUser }, (modify) => ({
  setCurrentUser(user?: CurrentUser) {
    modify((draft) => {
      draft.user = user;
    });
  },
}));

export default CurrentUserContext;
