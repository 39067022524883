import axios from 'axios';
import _ from 'lodash';
import { message } from 'antd';
import { captureException } from '@sentry/react';
import { matchPath } from 'react-router-dom';

export async function callApi<T>(
  params: {
    method: 'get' | 'post' | 'put' | 'delete' | 'patch';
    url: string;
    data?: { [key: string]: any };
    params?: { [key: string]: any };
    responseType?: 'blob';
  },
  __?: string,
  secret?: string
) {
  const headers: { [key: string]: any } = {};
  const token = localStorage.getItem('token');
  const urlPath = matchPath<{ orgId: string; teamId: string; projectId: string }>(
    window.location.pathname,
    {
      path: '/orgs/:orgId/teams/:teamId/projects/:projectId',
    }
  );
  const projectId = `project_${urlPath?.params.projectId}_token`;
  const projectToken = localStorage.getItem(projectId);

  if (projectToken) {
    headers['Authorization'] = projectToken;
  } else if (token) {
    headers['Authorization'] = token;
  }

  if (secret) {
    headers['Secret'] = secret;
  }
  try {
    const res = await axios({
      ...params,
      headers,
      url: process.env.REACT_APP_API_URL + params.url,
    });

    return res.data as T;
  } catch (error) {
    const data = _.get(error, 'response.data');
    const status = _.get(error, 'response.status');

    if (data && status >= 400 && status < 500) {
      if (status === 401 && !['/logout', '/login'].includes(window.location.pathname)) {
        window.location.replace('/logout');
      } else if (_.isArray(data.errors)) {
        data.errors.forEach((item: any) => {
          message.error(typeof item === 'string' ? item : item.message);
        });
      } else {
        message.error(data.errors.message);
      }
    }

    if (status > 500) {
      //@ts-ignore
      captureException(error.response);
    }

    //@ts-ignore
    return Promise.reject(error.response);
  }
}
