import { Team } from 'app/modules/sdk/user';
import React from 'react';

type InitialValueProps = {
  selectTeam: (id: string | React.ReactText) => void;
  selectedTeam?: Team;
  teams?: Team[];
  defaultTeam?: Team;
  setTeams: React.Dispatch<React.SetStateAction<Team[] | undefined>>;
  setTeam: React.Dispatch<React.SetStateAction<any>>;
};

const initialValue: InitialValueProps = {
  selectTeam: () => {},
  selectedTeam: undefined,
  setTeams: () => {},
  teams: undefined,
  setTeam: () => {},
  defaultTeam: undefined,
};

const TeamContext = React.createContext(initialValue);

const useTeamContext = () => {
  const [selectedTeam, setTeam] = React.useState<Team>();
  const [teams, setTeams] = React.useState<Team[] | undefined>(undefined);
  const [defaultTeam, setDefaultTeam] = React.useState<Team | undefined>(undefined);

  React.useEffect(() => {
    const isDefaultTeam = teams?.find((team) => team.name === 'My Team');
    setDefaultTeam(isDefaultTeam);
  }, [teams]);

  const selectTeam = React.useCallback(
    (id: string | React.ReactText) => {
      const filteredTeam = teams?.find((team) => team.id === id);
      setTeam(filteredTeam);
    },
    [teams]
  );

  React.useEffect(() => {
    if (!teams || selectedTeam) return;

    const orgIdFromStorage = localStorage.getItem('teamId');

    const activeTeam = teams.find((org) => org.id === orgIdFromStorage) || teams[0];
    setTeam(activeTeam);
  }, [teams, selectedTeam]);

  React.useEffect(() => {
    if (selectedTeam) {
      localStorage.setItem('teamId', selectedTeam.id);
    }
  }, [selectedTeam]);

  return {
    selectTeam,
    selectedTeam,
    teams,
    setTeams,
    setTeam,
    defaultTeam,
  };
};

const TeamProvider = (props: { children: React.ReactNode }) => {
  const { Provider } = TeamContext;
  const hookValue = useTeamContext();
  return <Provider value={hookValue}>{props.children}</Provider>;
};

export { TeamContext, TeamProvider };
