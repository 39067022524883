import { captureException } from '@sentry/react';
import getFlags from 'app/modules/common/flags/getFlags';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';

const isFlagsEnabled = process.env.REACT_APP_DEPLOY_ENV === 'production';
const FLAGS_API = process.env.REACT_APP_FLAGS_API || null;

const FlagsContext = React.createContext<Record<string, boolean>>({});

export const FlagsProvider: React.FC = ({ children }) => {
  const [flags, setFlags] = useState<Record<string, boolean>>({});

  const { data: resolvedFlags } = useSWR(isFlagsEnabled ? FLAGS_API : null, () => getFlags(), {
    onError: (error) => {
      captureException(error);
    },
    revalidateOnFocus: true,
  });

  useEffect(() => {
    if (!isFlagsEnabled) {
      const proxy = new Proxy({}, { get: () => true });
      setFlags(proxy);
    }
  }, []);

  useEffect(() => {
    if (resolvedFlags) {
      const result = resolvedFlags.reduce((acc, item) => {
        acc[item.name] = item.active;

        return acc;
      }, {} as Record<string, boolean>);

      setFlags(result);
    }
  }, [resolvedFlags]);

  return <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>;
};

export default FlagsContext;
